import { useTranslate } from "src/i18n/useTranslate"
import { InfoBox } from "src/ui/InfoBox/InfoBox"

export function CustomPlanInfoBox() {
  const { t, langKeys } = useTranslate()

  return (
    <InfoBox
      title={t(langKeys.change_plan_custom_plan_blocker_title)}
      type="warning"
    >
      {t(langKeys.change_plan_custom_plan_blocker_body)}
    </InfoBox>
  )
}
